label.label input[type="file"] {
  position: absolute;
  top: -1000px;
}

.label {
  cursor: pointer;
  border-radius: 5px;
  display: inline-block;
}


.label:invalid+span {
  color: #000000;
}

.label:valid+span {
  color: #ffffff;
}


/* segment */


.selected-item {
  text-align: center;
}

.controls-container {
  --highlight-width: auto;
  --highlight-x-pos: 0;
  display: flex;
  margin: 10px 0 10px;
}

.controls {
  display: inline-flex;
  justify-content: space-between;
  background: #FEF6F6;
  border: 1px solid #FCE3E3;
  border-radius: 8px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  padding: 5px;
  overflow: hidden;
  position: relative;
  font-size: 10px;
}

.controls input {
  opacity: 0;
  margin: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  position: absolute;
  width: 100%;
  cursor: pointer;
  height: 100%;
}

.segment {
  /* width: 100%; uncomment for each segment to have matching width */
  position: relative;
  text-align: center;
  z-index: 1;
}

.segment label {
  cursor: pointer;
  display: block;
  padding: 7px 12px;
  transition: color 0.5s ease;
  color: #696F79
}

.segment.active label {
  color: #fff;
}

.controls::before {
  content: "";
  background: #F16253;
  border-radius: 8px;
  width: var(--highlight-width);
  transform: translateX(var(--highlight-x-pos));
  position: absolute;
  top: 5px;
  bottom: 5px;
  left: 0;
  z-index: 0;
}

/* Only allow transitions once component is ready */
.controls.ready::before {
  transition: transform 0.3s ease, width 0.3s ease;
}