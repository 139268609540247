.vectortopleft-image-style {
  position: absolute;
  left: 0%;
  top: 0%;
}

.vectortopright-image-style {
  position: absolute;
  right: 0%;
  top: 0%;
}

.vectorbottomleft-image-style {
  position: absolute;
  left: 0%;
  bottom: 0%;
}

.vectorbottomright-image-style {
  position: absolute;
  right: 0%;
  bottom: 0%;
}