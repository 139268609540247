@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@200;300;400;500;600;700;800&display=swap');

body {
  margin: 0;
  font-family: 'Rubik', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
  width: 100vw;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.pier {
  cursor: pointer;
}

.tracker-row {
  cursor: pointer;
}

.selected-row .pier {
  fill: red;
}

.selected-pier .pier {
  fill: green;
}

.svgmap {
  font-family: "Manrope", sans-serif;
}

.svgmap path {
  cursor: pointer;
}

.svgmap path:hover {
  cursor: pointer;
  /* fill: rgb(238, 238, 238) !important; */
}

.textContainer {
  display: block;
  width: 180px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.listitem {
  padding: 1px 28px 1px 8px !important
}

.lsitem {
  padding: 2px 8px 0 17px !important
}

input:focus {
  outline: none;
}

.selectstyle {
  padding-right: -30px !important;
}

.checkbox-round {
  width: 1.3em;
  height: 1.3em;
  background-color: white;
  border-radius: 50%;
  vertical-align: middle;
  border: 1px solid #ddd;
  appearance: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
}

.checkbox-round:checked {
  border: 1px solid green;
}

.nextline {
  white-space: pre-wrap;
  /* or pre-line */
}

.icon-container {
  display: inline-block;
}

.icon {
  opacity: 0;
  transition: opacity 2s ease-in;
  /* Adjust timing and easing as needed */
}

.icon-container .icon {
  opacity: 1;
}

/* 
.custom-tab-panel {
  padding: 0 !important;
} */

.css-19kzrtu {
  padding: 0 !important;
}

.MuiTabScrollButton-root {
  width: 35px !important
}

.react-datepicker-wrapper input[type="text"]{
  border: none;
  font-weight: 500;
  background-color: transparent;
  font-size: 14px;
  font-family: inherit;
  color:"#696F79"!important;
  width:50
}
.react-datepicker__day {
  color: #ff0000; /* Change this color to your desired text color */
}
